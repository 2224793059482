@import '@coyo/ui/lib/colors';
@import "../../../src/main/styles/variables";

// You can add your variables here
$coyo-notification-badge-background-color: $b400;

$print-body-width: 860px;

/*
 * ===== Theming
 */

$coyo-button-primary-color: $btn-primary-color;
$coyo-button-primary-background-color: $btn-primary-bg;
$coyo-button-primary-hover-background-color: lighten($btn-primary-bg, 10%);

$coyo-button-accent-background-color: $link-color;
$coyo-button-accent-hover-background-color: lighten($link-color, 10%);

// This needs to be placed below theming overrides so that
// indirect variables inherit the correct value.
@import '@coyo/ui/lib/variables';

/**
 * ===== Downgrade
 */

$coyo-card-padding-h: $panel-body-padding-horizontal;
$coyo-card-padding-v: $panel-body-padding-vertical;

/**
 * ===== Theming (DEPRECATED)
 */

$coyo-link-color: $link-color;
$coyo-btn-primary-color: $btn-primary-bg;
$coyo-btn-primary-text-color: $btn-primary-color;
$coyo-btn-primary-hover-color: lighten($coyo-btn-primary-color, 10%);
$coyo-btn-primary-pressed-color: darken($coyo-btn-primary-color, 10%);

// import new CUI variables
@import '../../node_modules/@coyoapp/ui/styles/variables';
@import '../../node_modules/@coyoapp/ui/styles/mixins';
