@import "variables";
@import "mixins";

$bg-disabled: $n50;
$bg-disabled-text: $n100;
$bg-hover: $color-gray-lighter;
$bg-selected: $color-gray-lighter;
$bg-value-hover: $color-gray-lighter;
$bg-value: $color-gray-lighter;
$bg: $color-white;
$border-focus: $coyo-input-border-focus;
$border-radius: 5px;
$border-value: $color-gray-light;
$border: $color-gray-light;
$text-arrow-hover: $color-gray-darker;
$text-arrow: $color-gray;
$text-clear-hover: $color-gray-darker;
$text-clear: $color-gray;
$text-placeholder: $color-gray;

.ng-select:not(.mat-ng-select, .cui-ng-select) {
  &.ng-select-opened {
    > .ng-select-container {
      background: $bg;
      border-color: $border;

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        top: -2px;
        border-color: transparent transparent $text-arrow;
        border-width: 0 5px 5px;

        &:hover {
          border-color: transparent transparent $text-arrow-hover;
        }
      }
    }

    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $border-focus;
      box-shadow: 0 0 0 1px $border-focus;
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $bg-disabled;
      color: $bg-disabled-text;
      cursor: not-allowed;

      .ng-value-container {
        .ng-value,
        .ng-input > input {
          cursor: not-allowed;
        }
      }

      input[disabled] {
        cursor: not-allowed;
      }

      .ng-arrow-wrapper {
        color: $bg-disabled-text;
        cursor: not-allowed;

        &:hover {
          background-color: $bg-disabled-text;
          border-color: transparent transparent $bg-disabled-text;
        }
      }
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    background-color: $bg;
    border-radius: $border-radius;
    border: 1px solid $border;
    min-height: 40px;
    align-items: center;

    .ng-value-container {
      align-items: center;
      padding-left: 10px;

      .ng-placeholder {
        color: $text-placeholder;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: 40px;

      .ng-value-container {
        .ng-input {
          top: 5px;
          left: 0;
          padding-left: 10px;
          padding-right: 50px;
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $bg-value;
        border: 1px solid $border-value;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;

        .ng-value {
          padding: 4px 8px 4px 8px;
          font-size: 0.9em;
          margin-bottom: 5px;
          background-color: $n0;
          border-radius: 2px;
          border: 1px solid $border-value;
          margin-right: 5px;

          &.ng-value-disabled {
            background-color: $bg-value;
            border: 1px solid $border-value;

            .ng-value-label {
              padding-left: 5px;
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 0 5px 0 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 0 5px;

            &:hover {
              background-color: $bg-value-hover;
            }

            &.left {
              border-right: 1px solid $border-value;
            }

            &.right {
              border-left: 1px solid $border-value;
            }
          }
          .ng-value-clear {
            padding-left: 8px;
            float:right;
            font-size:22.5px;
            font-weight:600;
            line-height:1;
            color:#000;
            text-shadow:0 1px 0 #fff;
            opacity:.2;
            filter:alpha(opacity=20)
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;
        }

        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: $text-clear;

    &:hover .ng-clear {
      color: $text-clear-hover;
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;

    &:hover {
      .ng-arrow {
        border-top-color: $text-arrow-hover;
      }
    }

    .ng-arrow {
      border-color: $text-arrow transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }
}

.has-success .ng-select .ng-select-container {
  border-color: $brand-success;
}

.has-warning .ng-select .ng-select-container {
  border-color: $brand-warning;
}

.has-error .ng-select .ng-select-container {
  border-color: $brand-danger;
}

.ng-dropdown-panel {
  // ensure that the ng-dropdown-panel is rendered correctly above the CDK overlay container and above dialogs.
  // This should be deleted as soon as Bootstrap components have been eliminated.
  z-index: 1600 !important;
}

*:not(.cui-ng-select) > .ng-dropdown-panel {
  background-color: $bg;
  border: 1px solid $border;
  left: 0;
  overflow-scrolling: touch;

  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-top-color: $border;
    margin-top: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
      }
    }
  }

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: $border;
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $border-radius;
          border-top-left-radius: $border-radius;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $border;
    padding: 8px 16px;
    text-align: center;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $border;
    padding: 8px 16px;
  }

  .ng-dropdown-panel-items {
    margin-bottom: 1px;
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $bg-hover;
      }

      &.ng-option-selected {
        background-color: $bg-selected;
        font-weight: 600;
      }
    }

    .ng-option {
      background-color: $bg;
      color: rgba(0, 0, 0, .87);
      padding: 8px 10px;

      &.ng-option-selected {
        color: #333;
        background-color: $bg-selected;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        background-color: $bg-hover;
        color: #333;
      }

      &.ng-option-disabled {
        color: #cccccc;
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
  }
}

.coyo-ng-select-container .ng-select-overlay-container {
  position: relative;
  width: 100%;
  height: 100%;
  // ensure that the ng-select-overlay-container is rendered correctly above the CDK overlay container.
  // This should be deleted as soon as Bootstrap components have been eliminated.
  z-index: 1600;
}
