oyoc-maintenance-message {
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 1024;
  min-height: 50px;
}

.maintenance-message-container {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  background: $btn-danger-bg;
  padding: 0 10px;
  box-sizing: border-box;

  @media print, screen and (min-width: $screen-lg-min) {
    // TODO: improve the maintenance message container to make it work with the sidebar.
    right: $size-sidebar-messaging-compact;
    width: 100%;
  }
  .center-text {
    text-align: center;
    color: $color-white;
  }
  .maintenance-message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: $color-white;

    flex-direction: row;

    min-height: 50px;

    .zmdi-info {
      font-size: 20px;
      padding-right: 8px;
    }

    span {
      @include break-word();
      overflow-x: hidden;
      overflow-y: auto;
      text-align: center;
      max-height: 90vh;
      padding: 10px 0;
    }
  }

  a {
    color: $color-white;
    margin-right: 15px;

    &:hover {
      color: $color-gray-lighter;
    }
  }

}
