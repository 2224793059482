.front-content {
  h2 {
    display: flex;
    align-self: center;
    margin-bottom: 0;
    text-align: center;
  }
}

.copyright {
  background: $image-coyo-signature no-repeat right center;
  background-size: 90px;
  height: 24px;
  width: 90px;
  display: flex;
  align-self: center;
}

h1.logo {
  background-image: $image-coyo-front;
  background-position: center;

  background-repeat: no-repeat;
  @include media-retina() {
    background-image: $image-coyo-front-hd;
  }

  background-size: contain;
  height: calc(var(--height-image-front) * 0.8);
  text-indent: -9999px;

  @media print, screen and (min-width: $screen-sm-min) {
    height: $height-image-front;
  }
}
