// CSS Properties

$images: (
  image-coyo-nav: '/assets/images/logos/haiilo/logo-haiilo-inversed-nav.svg',
  image-coyo-nav-hd: '/assets/images/logos/haiilo/logo-haiilo-inversed-nav-hd.svg',
  image-coyo-front: '/assets/images/logos/haiilo/logo-haiilo-inversed-front.png',
  image-coyo-front-hd: '/assets/images/logos/haiilo/logo-haiilo-inversed-front-hd.png',
  image-coyo-signature: '/assets/images/logos/haiilo/logo-haiilo-signature.png'
);

$colors: (
  btn-primary-bg: #017384,
  btn-primary-color: #FFFFFF,
  color-background-main: #EBECF0,
  color-navbar-border: #FFFFFF,
  color-primary: #017384,
  color-secondary: #29A3B1,
  coyo-navbar-text: #FFFFFF,
  coyo-navbar: #017384,
  coyo-navbar-active: #01525E,
  link-color: #017384,
  text-color: #1B1F26,
  color-yellow: #f0ad4e,
  color-white: #ffffff,
  color-black: #333,
  color-blue: #317dc1,
  color-green: #9bbf29,
  color-red: #d0021b,
  color-orange: #dd8926,
  color-gray: #aaaaaa,
  color-gray-lighter: #f3f3f3,
  color-gray-light: #dadada,
  color-gray-dark: #999,
  color-gray-darker: #666,
  color-form-error: #a94442,
  color-form-correct: #9bbf29,
  color-link: #017384,
  color-original: #ffffff
);

:root {
  --font-family-base: "Source Sans Pro", sans-serif;
  --height-image-front: 80px;
  --width-navbar-brand: 125px;

  @each $name, $value in $images {
    --#{$name}: url(#{$value});
  }

  @each $name, $value in $colors {
    --#{$name}: #{$value};
    --#{$name}-h: #{strip-units(hue($value))};
    --#{$name}-s: #{saturation($value)};
    --#{$name}-l: #{lightness($value)};
  }


  --coyo-navbar-active-h: 0;
  --coyo-navbar-active-s: 0%;
  --coyo-navbar-active-l: 0%;
}

