@import "functions";

$link-color: var(--link-color);
$text-color: var(--text-color);

// fonts
// --------------------------------------------------
$font-coyo-woff: '/assets/fonts/Coyo4_File_Types.woff' !default;
$font-coyo-ttf: '/assets/fonts/Coyo4_File_Types.ttf' !default;

// images
// --------------------------------------------------
$image-coyo-nav: var(--image-coyo-nav) !default;
$image-coyo-nav-hd: var(--image-coyo-nav-hd) !default;
$image-coyo-front: var(--image-coyo-front) !default;
$image-coyo-front-hd: var(--image-coyo-front-hd) !default;
$image-coyo-signature: var(--image-coyo-signature) !default;
$image-coyo-heart: '/assets/images/logos/coyo/coyo-heart.svg' !default;
$image-bg-grid: '/assets/images/bg_grid.jpg' !default;

// animations
// --------------------------------------------------
$shake-ms: 500ms !default;

// breakpoints
// --------------------------------------------------
$layout-max-width: 1496px !default;
$breakpoint-messaging-xl: 1400px;

// colors
// --------------------------------------------------
$color-original: #ffffff !default;
$color-primary: var(--color-primary) !default;
$color-secondary: var(--color-secondary) !default;
$color-navbar-border: var(--color-navbar-border) !default;
$color-background-main: var(--color-background-main) !default;
$color-text-highlight-bg: rgba(255, 201, 0, 0.2) !default;

$color-white: var(--color-white) !default;
$color-black: var(--color-black) !default;
$color-blue: var(--color-blue) !default;
$color-green: var(--color-green) !default;
$color-yellow: var(--color-yellow) !default;
$color-red: var(--color-red) !default;
$color-orange: var(--color-orange) !default;

$color-gray: var(--color-gray) !default;
$color-gray-lighter: var(--color-gray-lighter) !default;
$color-gray-light: var(--color-gray-light) !default;
$color-gray-dark: var(--color-gray-dark) !default;
$color-gray-darker: var(--color-gray-darker) !default;

// borders
// --------------------------------------------------
$color-border: $color-gray-light !default;
$color-border-dark: $color-gray !default;
$color-border-light: $color-gray-lighter !default;

// error
// --------------------------------------------------
$color-valid: $color-green !default;
$color-error-border: #fff !default;
$color-error-bg: #c50b0b !default;
$color-error: #fff !default;

// links
// --------------------------------------------------
$color-link: var(--color-blue) !default;
$color-link-hover: darken($color-link, 15%) !default;

// previews
// --------------------------------------------------
$preview-gray: #f3f3f3;
$preview-gray-lighter: #f8f8f8;

// navbar
// --------------------------------------------------
$coyo-navbar:               var(--coyo-navbar) !default;
$coyo-navbar-active:        var(--coyo-navbar-active) !default;
$coyo-navbar-hover:         darken($coyo-navbar, 7.5%) !default;
$coyo-navbar-text:          var(--coyo-navbar-text) !default;
$coyo-navbar-text-active:   $color-white !default;
$coyo-navbar-text-disabled: $color-gray-light !default;
$coyo-navbar-input:         $coyo-navbar-active !default;
$coyo-navbar-input-active:  rgba(0, 0, 0, 0.4) !default;
$coyo-navbar-input-text:    $color-white !default;
$coyo-navbar-placeholder:   rgba(255, 255, 255, 0.5) !default;
$coyo-navbar-popup-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);

// buttons
// --------------------------------------------------
$btn-primary-bg: var(--btn-primary-bg) !default;
$btn-primary-color: var(--btn-primary-color) !default;

// forms
// --------------------------------------------------
$color-input-group-addon-bg: #fff !default;
$color-placeholder: #d6d6d6 !default;
$color-form-error: var(--color-form-error) !default;
$color-form-correct: var(--color-form-correct) !default;
$coyo-input-border-focus: $color-primary !default;
$input-inplace-edit-border: #dadada !default;

// sizes
// --------------------------------------------------
$size-content-max: $layout-max-width !default;
$size-sidebar-main: 275px !default;
$size-sidebar-messaging: 275px !default;
$size-sidebar-messaging-xs: 275px !default;
$size-sidebar-messaging-lg: 350px !default;
$size-sidebar-messaging-compact: 85px !default;
$height-image-front: var(--height-image-front) !default;
$size-sidebar-file-details: 470px !default;

// zindex
// --------------------------------------------------
$zindex-sidebar:               995 !default;
$zindex-messaging:             995 !default;
$zindex-topnav:                990 !default;
$zindex-subnav:                989 !default;

// comments
// --------------------------------------------------
$color-comments-bg: #F8F8F8 !default;

// Avatar backgrounds
// --------------------------------------------------
$color-page-avatar-bg: #DADADA !default;
$color-workspace-avatar-bg: #DADADA !default;
$color-event-avatar-bg: #DADADA !default;

// widgets
// --------------------------------------------------
$widget-line-height: 1.5;

// panels
// --------------------------------------------------
$panel-body-padding-negate-sm: -10px -18px !default;
$panel-body-padding-negate-md: -24px -30px !default;

// messaging sidebar
// --------------------------------------------------
$color-messaging-sidebar-bg: #444 !default;

// modals
// --------------------------------------------------
$coyo-modal-header-bg:         $coyo-navbar !default;
$coyo-modal-header-text:       $coyo-navbar-text !default;

// ribbons
// --------------------------------------------------
$coyo-ribbon-new-bg:           $color-blue !default;
$coyo-ribbon-new-bg-shadow:    darken($color-blue, 15%) !default;
$coyo-ribbon-sticky-bg:        $color-red !default;
$coyo-ribbon-sticky-bg-shadow: darken($color-red, 15%) !default;
$coyo-ribbon-read-bg:          $color-green !default;
$coyo-ribbon-read-bg-shadow:   darken($color-green, 15%) !default;
$coyo-ribbon-archived-bg:        $color-gray !default;
$coyo-ribbon-archived-bg-shadow: darken($color-gray, 15%) !default;

// measures
// --------------------------------------------------
$mobile-menu-item-height:      48px !default;

$panel-nav-padding-v:          10px !default;
$panel-xs-nav-padding:         $panel-nav-padding-v 18px !default;
$panel-sm-nav-padding:         $panel-nav-padding-v 24px !default;
$panel-nav-padding:            $panel-nav-padding-v 30px !default;

$panel-body-padding:           24px 30px !default;
$panel-body-padding-left:      #{nth($panel-body-padding, 2)} !default;
$panel-body-padding-right:     #{nth($panel-body-padding, 2)} !default;
$panel-body-padding-top:       #{nth($panel-body-padding, 1)} !default;
$panel-body-padding-bottom:    #{nth($panel-body-padding, 1)} !default;
$panel-body-padding-horizontal:#{nth($panel-body-padding, 2)} !default;
$panel-body-padding-vertical:  #{nth($panel-body-padding, 1)} !default;

$panel-sm-heading-margin:      0 24px 0 !default;
$panel-sm-heading-padding:     18px 0 12px !default;
$panel-sm-body-padding:        16px 24px !default;
$panel-sm-body-padding-left:      #{nth($panel-sm-body-padding, 2)} !default;
$panel-sm-body-padding-right:     #{nth($panel-sm-body-padding, 2)} !default;
$panel-sm-body-padding-top:       #{nth($panel-sm-body-padding, 1)} !default;
$panel-sm-body-padding-bottom:    #{nth($panel-sm-body-padding, 1)} !default;
$panel-sm-body-padding-horizontal:#{nth($panel-sm-body-padding, 2)} !default;
$panel-sm-body-padding-vertical:  #{nth($panel-sm-body-padding, 1)} !default;
$panel-sm-footer-padding:      14px 24px 16px !default;

$panel-xs-heading-margin:      0 18px 0 !default;
$panel-xs-heading-padding:     12px 0 8px !default;
$panel-xs-body-padding:        10px 18px !default;
$panel-xs-body-padding-left:      #{nth($panel-xs-body-padding, 2)} !default;
$panel-xs-body-padding-right:     #{nth($panel-xs-body-padding, 2)} !default;
$panel-xs-body-padding-top:       #{nth($panel-xs-body-padding, 1)} !default;
$panel-xs-body-padding-bottom:    #{nth($panel-xs-body-padding, 1)} !default;
$panel-xs-body-padding-horizontal:#{nth($panel-xs-body-padding, 2)} !default;
$panel-xs-body-padding-vertical:  #{nth($panel-xs-body-padding, 1)} !default;
$panel-xs-footer-padding:      14px 18px 16px !default;

// bootstrap variables
@import "bootstrap/bootstrap.variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_variables";
