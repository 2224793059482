/* You can add global styles to this file, and also import other style files */
@import "froala-editor/css/froala_editor.pkgd.min.css";
@import "froala-editor/css/froala_style.css";
@import "@ctrl/ngx-emoji-mart/picker";
@import "swiper/swiper-bundle";

/* inject:custom-vars */
@import "styles/variables";
@import "styles/mixins";
@import "styles/properties";

// Import Angular Material styles
@import "@coyo/ui/lib/styles";

// Import CUI styles
@import "../node_modules/@coyoapp/ui/styles/variables";
@import "../node_modules/@coyoapp/ui/styles/mixins";
@import "../node_modules/@coyoapp/ui/styles/styles";

// You can add your global styles here
@import "styles/body";
@import "styles/util";
@import "styles/spaces";
@import "styles/components/button";
@import "styles/components/dropdown";
@import "styles/components/select";
@import "styles/components/spinner";
@import "styles/coyo-zmdi-icons";
@import "styles/components/login";

// Print styles
@import "styles/print";

// You can add your global component styles here
// @import "app/shared/rte/rte/rte.component.global";
// @import "app/shared/social/timeline-share/timeline-share.component.global";
// @import "app/shared/emoji/emoji-picker/emoji-picker.component.global";
// @import "app/integration/gsuite/g-drive-picker/google-file-picker.global";
// @import "app/timeline/timeline-form/functional-user-chooser/functional-user-chooser.component.global";
// @import "app/shared/pdf/pdf-viewer/pdf-viewer.component.global";
// @import "app/engage/news/blog-article/blog-article-view.component.global";
// @import "app/shared/social/comments/comment/comment.component.global";
// @import "app/shared/social/comments/comment-form/comment-form.component.global";
// @import "app/shared/mention/mention.component.global";
// @import "app/messaging/message-form/message-form.component.global";
// @import "app/timeline/timeline-item/timeline-item/timeline-item.component.global";
// @import "app/shared/forms/date-picker/date-picker.component.global";
// @import "app/shared/forms/time-picker/time-picker.component.global";
// @import "app/widgets/widgets.global";
// @import "app/widgets/text/text-widget/text-widget.component.global";
// @import "app/widgets/image/image-widget/image-widget.component.global";
// @import "app/launchpad/launchpad/launchpad.component.global";
// @import "app/events/event.global";
// @import "app/widgets/download/download-widget/download-widget.component.global";
// @import "app/pages/subscriber-modal/subscriber-modal.component.global";
// @import "app/widgets/media/media-widget/media-widget.component.global";
// @import "app/widgets/teaser/teaser-widget/teaser-widget.component.global";
// @import "app/widgets/media/light-box/light-box.component.global";
// @import "app/events/event-settings/event-settings-modal.service.global";
// @import "app/widgets/image/image-widget-settings/image-widget-settings.component.global";
// @import "app/shared/help/help/help.component.global.scss";
// @import "app/shared/files/file-details-modal/file-details-modal.component.global";
// @import "app/file-picker/file-picker/file-picker.component.global";
// @import "app/account/settings/settings.component.global";
// @import "app/widgets/blog-article/blog-article-widget/blog-article-widget.component.global";
// @import "app/tour/tour.global";
// @import "app/filter/filter-box/filter-box.component.global";
// @import "app/apps/blog/blog-article-settings/blog-article-settings.component.global";
// @import "app/shared/hashtags/hashtag-select/hashtag-select.component.global";
// @import "app/shared/hashtags/hashtag-subscription-select/hashtag-subscription-select.component.global";
// @import "app/shared/blog-article-view/blog-article-slider-view/blog-article-slider-view.component.global";
// @import "app/shared/forms/text-alignment/text-alignment.component.global";
// @import "app/widgets/teaser/teaser-slider/teaser-slider.component.global";
// @import "app/shared/hero-teaser/hero-teaser/hero-teaser.component.global";
// @import "app/topic/topic-settings/topic-settings.component.global";
// @import "app/navigation/navigation.global";

// Inject custom SCSS code (must stay at end of file)
/* inject:custom-scss */

// Load Catalyst styles
$cat-font-path: '../../assets/fonts';
@import "@haiilo/catalyst/dist/catalyst/scss";

// Important: This import must stay at the end of this file!
@import "styles/migration";
