@import "variables";
@import "mixins";
@import "./mixins/buttons";
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";
@import "bootstrap-sass/assets/stylesheets/bootstrap/buttons";

.coyo-btn {
  @extend .btn;
  display: inline-flex;
  align-items: center;
  border: 2px solid transparent;
  position: relative;

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: none;
      outline-offset: initial;
    }
  }

  &:active,
  &.active {
    @include box-shadow(none);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(1);
  }

  &.btn-block,
  &.coyo-btn-block {
    display: flex;
    width: 100%;
  }
}


// Button styles
// --------------------------------------------------

.coyo-btn-primary {
  @include coyo-button-variant(
      $coyo-btn-primary-text-color, $coyo-btn-primary-color, null,         // normal
      $coyo-btn-primary-text-color, $coyo-btn-primary-hover-color, null,   // hover
      $coyo-btn-primary-text-color, null, $coyo-focus-color,               // focus
      $coyo-btn-primary-text-color, $coyo-btn-primary-pressed-color, null, // pressed
      $n100, $n50, null,                                                   // disabled
      $coyo-btn-primary-text-color, null, null);                           // active
}

.coyo-btn-secondary {
  @include coyo-button-variant(
      $n400, $n50, null,              // normal
      $n400, $n75, null,              // hover
      $n400, null, $coyo-focus-color, // focus
      $n400, $n100, null,             // pressed
      $n100, $n50, null,              // disabled
      $n400, null, null);             // active
}

.coyo-btn-link {
  @include coyo-button-variant(
      $n200, transparent, null,       // normal
      $n200, $n35, null,              // hover
      $n200, null, $coyo-focus-color, // focus
      $n200, $n75, null,              // pressed
      $n100, transparent, null,       // disabled
      $coyo-link-color, null, null);  // active
}

.coyo-btn-text {
  @include coyo-button-variant(
      $n200, transparent, null,       // normal
      $n200, transparent, null,       // hover
      $n200, null, $coyo-focus-color, // focus
      $n200, transparent, null,       // pressed
      $n100, transparent, null,       // disabled
      $coyo-link-color, null, null);  // active

  &:hover,
  &:active {
    text-decoration: underline;

    .zmdi,
    .zmdi::before {
      display: inline-block;
      text-decoration: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus > * {
      text-decoration: none;
    }
  }
}

// Button sizes
// --------------------------------------------------

.coyo-btn {
  @include coyo-button-size(
      6px, 14px,
      15px, 24px, 4px,
      18px, 10px, 24px);
}

.coyo-btn-sm {
  @include coyo-button-size(
      2px, 14px,
      15px, 24px, 4px,
      18px, 8px, 20px);
}


// Button icons
// --------------------------------------------------

.coyo-btn {
  .zmdi-file-plus {
    position: relative;
    top: -1px;
  }
}


// Deprecated
// --------------------------------------------------

.btn-icon {
  @extend .btn-link;
  display: inline-flex;
  padding: 7px;
  color: $color-gray;

  &:active,
  &.active,
  &:hover,
  &:focus {
    color: $color-gray-darker;
  }

  &[disabled],
  fieldset[disabled] & {
    &:active,
    &.active,
    &:hover,
    &:focus {
      color: $color-gray-light;
    }
  }

  i {
    margin: 0;
    font-size: 1.33333333em;
    line-height: 1.2em;
    width: 1.2em;
  }
}

.form-action-buttons.text-right {
  button.coyo-btn {
    margin-left: 8px;
  }
}

.form-action-buttons.text-left {
  button.coyo-btn {
    margin-right: 8px;
  }
}
