.sidebar {
  opacity: 1;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: $zindex-sidebar;
  background-color: $color-white;
  width: $size-sidebar-main;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  // active state
  &.slide-in {
    left: 0;
  }

  .sidebar-headline {
    border-bottom: 1px solid $color-gray-lighter;
    color: $color-gray;
    height: 50px;
    line-height: 50px;
    padding: 0 24px;
    text-transform: uppercase;
  }

  .nav-sidebar-item {
    * {
      @include user-select(none);
    }

    a {
      display: block;
      border-left: 4px solid transparent;
      color: $color-gray-darker;
      cursor: pointer;
      padding: 12px 30px;

      &.active {
        color: $color-link;
      }

      i {
        font-size: 20px;
      }
    }

    &.active a, &:hover a {
      color: $text-color;
      background-color: $color-gray-lighter;
      border-color: $color-link;
      margin: 0;
      text-decoration: none;
    }
  }

  .nav.nav-collapse {
    .sidebar-headline {
      cursor: pointer;

      &::before {
        content: '\f2f9';
        font-family: 'Material-Design-Iconic-Font';
        float: right;
        font-size: 18px;
      }
    }

    &.nav-collapsed-false {
      .sidebar-headline::before {
        content: '\f2fb';
      }

      li:not(.sidebar-headline) {
        display: none;
      }
    }
  }

  // Displays icons only
  &.sidebar-collapsed {
    @include sidebar-collapsed();
  }
}
