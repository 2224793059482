@import "mixins";

body > .ui-select-bootstrap.open {
  z-index: 2000 !important;
}

//give ngx modals a lower priority than ng1 modals
modal-container.modal {
  z-index: 1040;
}

.modal {
  &.fade .modal-dialog {
    @include scale(.5);
    @include transition-transform(0.15s ease-out);
    will-change: scale;
  }
  &.in .modal-dialog {
    @include scale(1);
  }
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  margin: 0 auto;
}

.modal-content {
  width: 100%;
}

.modal-content {
  border: 0;
}

.modal-content-wrapper {
  display: flex;
  flex-direction: column;
}

.modal-header {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  align-items: center;

  h3 {
    font-size: $font-size-h3;
    line-height: 1.6em;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 24px;
  }

  .modal-close {
    color: $color-gray;
    font-size: 20px;
    line-height: 32px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;

    &:hover {
      color: $color-gray-dark;
    }
  }
}

.modal-body {
  flex: 1;
}

.modal-footer {
  border: 0;
  display: flex;
  overflow: hidden;

  .btn {
    border: 0;
    flex: 1 0 auto;

    &.btn-default {
      background-color: $color-gray-lighter;

      &:active, &:focus, &:hover {
        background-color: $color-gray-light;
      }
    }

    + .btn {
      margin-left: 0;
    }
  }
}

@mixin modal-content-small-screen() {

  .modal-mobile-hide { display: none !important; }

  .modal-content-wrapper,
  .modal-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    width: auto;
  }

  .modal-content {
    border-radius: 0;
    height: 100%;
  }

  .modal-content-wrapper {
    height: 100%;
  }

  .modal-header {
    padding: 7px 16px;
    background-color: $coyo-modal-header-bg;
    border: 0;
    color: $coyo-modal-header-text;
    min-height: 46px;

    h3 {
      font-size: $font-size-h4;
      text-align: center;
      margin: 0 24px;
    }

    .modal-close {
      color: $coyo-modal-header-text;
      right: 16px;
    }
  }

  .modal-body {
    overflow: scroll;
    padding: 16px;

    .item-selected {
      color: $color-green;
      margin-right: 10px;
    }

    .item-not-selected {
      color: opacify($color-white, 1.0);
      margin-right: 10px;

      &:active {
        color: opacify($color-gray-light, 1.0);
      }
    }
  }

  .modal-footer {
    padding: 16px;
    flex-direction: column;

    .btn {
      margin-bottom: 16px;
      padding-bottom: 9px;
      padding-top: 9px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-mobile-options {
    font-size: 15px;
    list-style: none;
    margin: -16px;
    padding: 0;
  }

  .modal-mobile-option {
    border-bottom: 1px solid $color-gray-light;
    padding: 12px 16px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:active {
      background-color: $color-gray-light;
    }
  }

  .sr-close-button:focus {
    display: inline-block;
    position: relative;
    top: 2em;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 10px;
    color: $color-gray-light;
  }
}

@mixin modal-content-big-screen() {
  text-align: center;

  .modal-mobile-show { display: none !important; }

  .modal-dialog {
    text-align: left;
    vertical-align: middle;
  }

  .fullscreen {
    width: 100%;
    margin: 0;
    padding: 0;

    .modal-content {
      min-height: 100vh;
      border-radius: 0;
    }
  }

  .modal-content {
    border-radius: ($border-radius-base + 1px);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  }

  .modal-header {
    margin: $panel-sm-heading-margin;
    padding: $panel-sm-heading-padding;
    min-height: 63px;
  }

  .modal-body {
    flex: 1 0 auto;
    padding: $panel-sm-body-padding;

    hr {
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  .modal-footer {
    flex-direction: row-reverse;
    min-height: 48px;
    padding: 0;

    .btn {
      border-radius: 0;
      margin: 0;
      padding-bottom: 12px;
      padding-top: 12px;

      + .btn {
        border-right: 1px solid rgba(255,255,255,0.25);
      }

      &.btn-condensed {
        flex: none;
        padding-left: 50px;
        padding-right: 50px;
      }

      &:first-child { border-bottom-right-radius: $border-radius-base; }
      &:last-child { border-bottom-left-radius: $border-radius-base; }
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

.modal:not(.small-sm) {
  @include xs {
    @include modal-content-small-screen;
  }

  @include sm-and-up {
    @include modal-content-big-screen
  }
}

.modal.small-sm {
  @include sm-and-down {
    @include modal-content-small-screen;
  }

  @include md-and-up {
    @include modal-content-big-screen;
  }
}
