h1.logo {
  flex-grow: 1;
  margin:0;
  background-image: $image-coyo-front;
  background-position: center;

  background-repeat: no-repeat;
  @include media-retina() {
    background-image: $image-coyo-front-hd;
  }

  background-size: contain;
  color: white;
  font-size: 60px;
  height: calc(var(--height-image-front) * 0.8);
  margin: 12px 0 30px;
  text-indent: -9999px;

  @media print, screen and (min-width: $screen-sm-min) {
    height: $height-image-front;
  }
}